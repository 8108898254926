import { Plugin } from '@nuxt/types'

import { MetaInfo } from 'vue-meta/types'

import LANG from '../lang/pt'

type utilType = {
    textShortener: (text: string, maxLength: number) => string
    replacePlaceholders: (placeholders: [string, string][], raw: string | string[]) => string | string[]
    metaInit: (
        tags: [string, string][],
        title: string
    ) => {
        meta: MetaInfo['meta']
        title: string
    }
    host: string | undefined
    lang: typeof LANG
}

declare module 'vue/types/vue' {
    interface Vue {
        $util: utilType
    }
}

declare module '@nuxt/types' {
    interface Context {
        $util: utilType
    }
}

const util: Plugin = (ctx, inject) => {
    inject('util', {
        replacePlaceholders: (placeholders: [string, string][], raw: string | string[]) => {
            if (typeof raw === 'string') {
                return placeholders.reduce(
                    (text, placeholders) => text?.replace(new RegExp(placeholders[0], 'gi'), placeholders[1]),
                    raw
                )
            }

            if (raw instanceof Array) {
                return raw.map((text) => {
                    return placeholders.reduce((text, placeholder) => {
                        return text?.replace(new RegExp(placeholder[0], 'gi'), placeholder[1])
                    }, text)
                })
            }

            return ''
        },
        host: process.env.HOST,
        metaInit: (tags: [string, string][], title: string) => {
            const init: {
                meta: { [key: string]: string }[]
                title: string
            } = {
                meta: [],
                title: '',
            }

            if (tags.length) {
                tags.forEach((meta) => {
                    const type = /og:/.test(meta[0]) ? 'property' : 'name'

                    !!meta[1] && init.meta.push({ [type]: meta[0], content: meta[1], hid: meta[0] })
                })
            }

            init.title = (title || (ctx.app?.head as MetaInfo).title) as string

            return init
        },
        textShortener: (text: string, maxLength: number) => {
            if (!text) return ''

            let joined = ''

            const splitted = text.split(' ')

            for (const word of splitted) {
                const suspensivePoints = joined.charAt(joined.length - 1) === '.' ? '..' : '...'

                if (joined.length + word.length + suspensivePoints.length > maxLength) {
                    joined = joined.concat(suspensivePoints)

                    break
                }

                joined = joined.concat(' ' + word)
            }

            return (joined.charAt(joined.length - 1) === '.' ? joined : joined.concat('.')).trim()
        },
        lang: LANG,
    })
}

export default util

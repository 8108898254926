import lang from '../lang/pt'

const processEnvMode = (process.env.ENV_MODE || 'development') as 'development' | 'production'
const baseUrl = (process.env.API_URL || 'https://sandbox-api.okdescuentos.com/api') as string

const siteLanguage = {
    env: 'pt' as 'pt',
    code: 'pt-BR' as 'pt-BR',
}

const siteStaticRoutesToIndex = [
    {
        url: '/' + lang.routes.about_us,
        priority: 0.1,
        changefreq: 'monthly',
        lastmod: new Date(),
    },
    {
        url: '/' + lang.routes.rules,
        priority: 0.1,
        changefreq: 'monthly',
        lastmod: new Date(),
    },
    {
        url: '/' + lang.routes.blog,
        priority: 0.7,
        changefreq: 'daily',
        lastmod: new Date(),
    },
    {
        url: '/' + lang.routes.share,
        priority: 0.1,
        changefreq: 'monthly',
        lastmod: new Date(),
    },
    {
        url: '/' + lang.routes.contact,
        priority: 0.1,
        changefreq: 'monthly',
        lastmod: new Date(),
    },
    {
        url: '/' + lang.routes.coupons,
        priority: 0.6,
        changefreq: 'daily',
        lastmod: new Date(),
    },
    {
        url: '/' + lang.routes.events,
        priority: 0.7,
        changefreq: 'daily',
        lastmod: new Date(),
    },
    {
        url: '/' + lang.routes.expired,
        priority: 0.7,
        changefreq: 'daily',
        lastmod: new Date(),
    },
    /*  {
        url: '/' + lang.routes.extension,
        priority: 0.1,
        changefreq: 'monthly',
        lastmod: new Date(),
    }, */
    {
        url: '/' + lang.routes.faq,
        priority: 0.1,
        changefreq: 'monthly',
        lastmod: new Date(),
    },
    /*  {
        url: '/' + lang.routes.forum,
        priority: 0.7,
        changefreq: 'daily',
        lastmod: new Date(),
    }, */
    {
        url: '/' + lang.routes.free,
        priority: 0.7,
        changefreq: 'daily',
        lastmod: new Date(),
    },
    /* {
        url: '/' + lang.routes.novelties,
        priority: 0.5,
        changefreq: 'weekly',
        lastmod: new Date(),
    }, */
    {
        url: '/' + lang.routes.offers,
        priority: 0.6,
        changefreq: 'daily',
        lastmod: new Date(),
    },
    /* {
        url: '/' + lang.routes.register,
        priority: 0.1,
        changefreq: 'monthly',
        lastmod: new Date(),
    }, */
    {
        url: '/',
        priority: 1,
        changefreq: 'hourly',
        lastmod: new Date(),
    },
]

const siteFavicon = '/favicon/okdescontos.png'

const siteName = 'OKdescontos'

const siteOrigin = 'https://www.okdescontos.com.br'

const siteFinalHost = 'www.okdescontos.com.br'

const siteBaseURL = {
    development: 'https://sandbox-api.okdescuentos.com/api',

    production: 'https://admin.okdescontos.com.br/api',
}

const siteSitemapsPrefixes = {
    general: {
        /* novelties: `/${lang.routes.novelties}/`, */
        articles: `/${lang.routes.blog}/`,
        events: `/${lang.routes.events}/`,
    },
    stores: `/${lang.routes.brands}/`,
    categories: `/${lang.routes.categories}/`,
    /*  chats: `/${lang.routes.forum}/`, */
    offers: `/${lang.routes.offers}/`,
    coupons: `/${lang.routes.coupons}/`,
}

/* 

Disallow: /search
Disallow: *?slug=*
Disallow: /cdn-cgi/*
Disallow: /local
Disallow: /assets
Disallow: /_nuxt
Disallow: /open
Disallow: /go
Disallow: /lp
Disallow: /js

*/

const robotsDisallowUrls = [
    `/${lang.routes.search}`,
    '*?slug=*',
    '/cdn-cgi/*',
    '/local',
    '/assets',
    '/_nuxt',
    '/open',
    '/go',
    '/lp',
    '/js',
]

const GTMKeys = 'GTM-W9TV8JGC'

const defaultMetaTags = [
    {
        name: 'google-site-verification',
        content: 'JOXyC0ZuvdK5RXMi7V1eKYXIJpGN03BZD3mXVWdvXsI',
    },
    {
        name: 'ir-site-verification-token',
        content: '-378293540',
    },
    {
        name: 'verify-admitad',
        content: '87c5608d9e',
    },
    {
        name: 'lomadee-verification',
        content: '23361522',
    },
    {
        hid: 'og:image',
        property: 'og:image',
        content: siteOrigin + `/assets/jpg/okdescontos/preview.jpg`,
    },
    {
        hid: 'og:image:secure_url',
        property: 'og:image:secure_url',
        content: siteOrigin + `/assets/jpg/okdescontos/preview.jpg`,
    },
    {
        hid: 'twitter:image',
        property: 'twitter:image',
        content: siteOrigin + `/assets/jpg/okdescontos/preview.jpg`,
    },
    {
        hid: 'og:site_name',
        property: 'og:site_name',
        content: siteName,
    },
    {
        hid: 'og:type',
        property: 'og:type',
        content: 'website',
    },
    {
        hid: 'og:locale',
        property: 'og:locale',
        content: siteLanguage.code,
    },
    {
        hid: 'twitter:card',
        property: 'twitter:card',
        content: 'summary',
    },
]

export const variantConfig = {
    siteFinalHost,
    siteName,
    siteFavicon,
    siteOrigin,
    siteStaticRoutesToIndex,
    siteSitemapsPrefixes,
    robotsDisallowUrls,
    siteVariant: 'okdescontos',
    siteMode: processEnvMode,
    siteBaseURL: siteBaseURL[processEnvMode],
    siteLang: siteLanguage,
    GTMKey: GTMKeys,
    metaTags: defaultMetaTags,
}

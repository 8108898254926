import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _c3f0ee36 = () => interopDefault(import('../pages/aviso-legal.vue' /* webpackChunkName: "pages/aviso-legal" */))
const _a536af7a = () => interopDefault(import('../pages/ayuda.vue' /* webpackChunkName: "pages/ayuda" */))
const _411f94ac = () => interopDefault(import('../pages/blog/index.vue' /* webpackChunkName: "pages/blog/index" */))
const _0604071a = () => interopDefault(import('../pages/cambiar-contrasena.vue' /* webpackChunkName: "pages/cambiar-contrasena" */))
const _32baa1c2 = () => interopDefault(import('../views/CategoriesBridge.vue' /* webpackChunkName: "" */))
const _ec68cd2a = () => interopDefault(import('../views/Homepage.vue' /* webpackChunkName: "" */))
const _472c90e9 = () => interopDefault(import('../pages/compartir/index.vue' /* webpackChunkName: "pages/compartir/index" */))
const _8db278d4 = () => interopDefault(import('../pages/contacto.vue' /* webpackChunkName: "pages/contacto" */))
const _64311cb4 = () => interopDefault(import('../pages/contrasena-actualizada-exitosamente.vue' /* webpackChunkName: "pages/contrasena-actualizada-exitosamente" */))
const _2fd338d0 = () => interopDefault(import('../pages/cookies.vue' /* webpackChunkName: "pages/cookies" */))
const _584cbc97 = () => interopDefault(import('../pages/cupones/index.vue' /* webpackChunkName: "pages/cupones/index" */))
const _4d8e75a0 = () => interopDefault(import('../pages/dashboard/index.vue' /* webpackChunkName: "pages/dashboard/index" */))
const _705777ba = () => interopDefault(import('../pages/dashboard/index/index.vue' /* webpackChunkName: "pages/dashboard/index/index" */))
const _7a6bcce4 = () => interopDefault(import('../pages/dashboard/index/actividad.vue' /* webpackChunkName: "pages/dashboard/index/actividad" */))
const _8748c268 = () => interopDefault(import('../pages/dashboard/index/ajustes.vue' /* webpackChunkName: "pages/dashboard/index/ajustes" */))
const _599226ec = () => interopDefault(import('../pages/dashboard/index/chats.vue' /* webpackChunkName: "pages/dashboard/index/chats" */))
const _6102958a = () => interopDefault(import('../pages/dashboard/index/descuentos.vue' /* webpackChunkName: "pages/dashboard/index/descuentos" */))
const _4a58c986 = () => interopDefault(import('../pages/dashboard/index/guardados.vue' /* webpackChunkName: "pages/dashboard/index/guardados" */))
const _6b1ca1ae = () => interopDefault(import('../pages/dashboard/index/monedas.vue' /* webpackChunkName: "pages/dashboard/index/monedas" */))
const _2fab8e34 = () => interopDefault(import('../pages/dashboard/index/premios.vue' /* webpackChunkName: "pages/dashboard/index/premios" */))
const _00e998ca = () => interopDefault(import('../pages/eventos/index.vue' /* webpackChunkName: "pages/eventos/index" */))
const _12adefd4 = () => interopDefault(import('../pages/expirados.vue' /* webpackChunkName: "pages/expirados" */))
const _7c4fb11f = () => interopDefault(import('../pages/faq.vue' /* webpackChunkName: "pages/faq" */))
const _05217cb9 = () => interopDefault(import('../pages/foros/index.vue' /* webpackChunkName: "pages/foros/index" */))
const _25aed2e2 = () => interopDefault(import('../pages/gratis.vue' /* webpackChunkName: "pages/gratis" */))
const _137a8dac = () => interopDefault(import('../pages/inicio-sin-contrasena.vue' /* webpackChunkName: "pages/inicio-sin-contrasena" */))
const _2231dbb2 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _3c6ede61 = () => interopDefault(import('../views/InstallExtension.vue' /* webpackChunkName: "" */))
const _233b9350 = () => interopDefault(import('../views/StoresBridge.vue' /* webpackChunkName: "" */))
const _0182f756 = () => interopDefault(import('../pages/mensajeria/index.vue' /* webpackChunkName: "pages/mensajeria/index" */))
const _f3491a5e = () => interopDefault(import('../pages/notificaciones.vue' /* webpackChunkName: "pages/notificaciones" */))
const _440ecbde = () => interopDefault(import('../pages/novedades/index.vue' /* webpackChunkName: "pages/novedades/index" */))
const _c67f375e = () => interopDefault(import('../pages/nueva-contrasena.vue' /* webpackChunkName: "pages/nueva-contrasena" */))
const _2a2e822e = () => interopDefault(import('../pages/ofertas/index.vue' /* webpackChunkName: "pages/ofertas/index" */))
const _59b4b482 = () => interopDefault(import('../pages/olvide-mi-contrasena.vue' /* webpackChunkName: "pages/olvide-mi-contrasena" */))
const _0b06205b = () => interopDefault(import('../pages/politicas-de-privacidad.vue' /* webpackChunkName: "pages/politicas-de-privacidad" */))
const _d7f8dcea = () => interopDefault(import('../views/Preferences.vue' /* webpackChunkName: "" */))
const _1420a080 = () => interopDefault(import('../pages/prensa.vue' /* webpackChunkName: "pages/prensa" */))
const _1c63017a = () => interopDefault(import('../pages/registro.vue' /* webpackChunkName: "pages/registro" */))
const _40922a10 = () => interopDefault(import('../pages/reglas-de-comunidad.vue' /* webpackChunkName: "pages/reglas-de-comunidad" */))
const _647bb42f = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "pages/search" */))
const _ce3f6a7e = () => interopDefault(import('../pages/sitemap/index.vue' /* webpackChunkName: "pages/sitemap/index" */))
const _194006f8 = () => interopDefault(import('../pages/sitemap/index/index.vue' /* webpackChunkName: "pages/sitemap/index/index" */))
const _83f34c92 = () => interopDefault(import('../pages/sitemap/index/promociones/index.vue' /* webpackChunkName: "pages/sitemap/index/promociones/index" */))
const _098ca3a6 = () => interopDefault(import('../pages/sitemap/index/tiendas/index.vue' /* webpackChunkName: "pages/sitemap/index/tiendas/index" */))
const _87608f22 = () => interopDefault(import('../pages/sitemap/index/promociones/_slug.vue' /* webpackChunkName: "pages/sitemap/index/promociones/_slug" */))
const _c32152f2 = () => interopDefault(import('../pages/sobre-nos.vue' /* webpackChunkName: "pages/sobre-nos" */))
const _78977093 = () => interopDefault(import('../pages/subscribe.vue' /* webpackChunkName: "pages/subscribe" */))
const _a7460b4c = () => interopDefault(import('../pages/unsubscribe.vue' /* webpackChunkName: "pages/unsubscribe" */))
const _c314575e = () => interopDefault(import('../pages/verificar-cuenta.vue' /* webpackChunkName: "pages/verificar-cuenta" */))
const _17651f91 = () => interopDefault(import('../pages/compartir/chat.vue' /* webpackChunkName: "pages/compartir/chat" */))
const _a62a5a92 = () => interopDefault(import('../pages/compartir/cupon/index.vue' /* webpackChunkName: "pages/compartir/cupon/index" */))
const _fd53a9c4 = () => interopDefault(import('../pages/compartir/finalizado.vue' /* webpackChunkName: "pages/compartir/finalizado" */))
const _18b2a0ad = () => interopDefault(import('../pages/compartir/oferta/index.vue' /* webpackChunkName: "pages/compartir/oferta/index" */))
const _009337ec = () => interopDefault(import('../pages/compartir/cupon/crear.vue' /* webpackChunkName: "pages/compartir/cupon/crear" */))
const _6c5b05e2 = () => interopDefault(import('../pages/compartir/oferta/crear.vue' /* webpackChunkName: "pages/compartir/oferta/crear" */))
const _08764e40 = () => interopDefault(import('../views/StoreMask.vue' /* webpackChunkName: "" */))
const _00d3660c = () => interopDefault(import('../pages/blog/_category/index.vue' /* webpackChunkName: "pages/blog/_category/index" */))
const _4e1f8a54 = () => interopDefault(import('../views/DiscountDetails.vue' /* webpackChunkName: "" */))
const _73334578 = () => interopDefault(import('../pages/dashboard/_user.vue' /* webpackChunkName: "pages/dashboard/_user" */))
const _22b85c0a = () => interopDefault(import('../pages/dashboard/_user/index.vue' /* webpackChunkName: "pages/dashboard/_user/index" */))
const _8e220934 = () => interopDefault(import('../pages/dashboard/_user/actividad.vue' /* webpackChunkName: "pages/dashboard/_user/actividad" */))
const _ff3c9678 = () => interopDefault(import('../pages/dashboard/_user/chats.vue' /* webpackChunkName: "pages/dashboard/_user/chats" */))
const _2f79eeb2 = () => interopDefault(import('../pages/dashboard/_user/descuentos.vue' /* webpackChunkName: "pages/dashboard/_user/descuentos" */))
const _019a10fc = () => interopDefault(import('../pages/eventos/_slug.vue' /* webpackChunkName: "pages/eventos/_slug" */))
const _036adb71 = () => interopDefault(import('../pages/foros/_slug.vue' /* webpackChunkName: "pages/foros/_slug" */))
const _1d8faf4a = () => interopDefault(import('../views/DiscountMask.vue' /* webpackChunkName: "" */))
const _477c0e6e = () => interopDefault(import('../pages/novedades/_slug.vue' /* webpackChunkName: "pages/novedades/_slug" */))
const _22df0382 = () => interopDefault(import('../pages/blog/_category/_article.vue' /* webpackChunkName: "pages/blog/_category/_article" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/aviso-legal",
    component: _c3f0ee36,
    name: "aviso-legal"
  }, {
    path: "/ayuda",
    component: _a536af7a,
    name: "ayuda"
  }, {
    path: "/blog",
    component: _411f94ac,
    name: "blog"
  }, {
    path: "/cambiar-contrasena",
    component: _0604071a,
    name: "cambiar-contrasena"
  }, {
    path: "/categorias",
    component: _32baa1c2,
    name: "categorias"
  }, {
    path: "/comentados",
    component: _ec68cd2a,
    name: "index-comentados"
  }, {
    path: "/compartir",
    component: _472c90e9,
    name: "compartir"
  }, {
    path: "/contacto",
    component: _8db278d4,
    name: "contacto"
  }, {
    path: "/contrasena-actualizada-exitosamente",
    component: _64311cb4,
    name: "contrasena-actualizada-exitosamente"
  }, {
    path: "/cookies",
    component: _2fd338d0,
    name: "cookies"
  }, {
    path: "/cupones",
    component: _584cbc97,
    name: "cupones"
  }, {
    path: "/dashboard",
    component: _4d8e75a0,
    children: [{
      path: "",
      component: _705777ba,
      name: "dashboard-index"
    }, {
      path: "actividad",
      component: _7a6bcce4,
      name: "dashboard-index-actividad"
    }, {
      path: "ajustes",
      component: _8748c268,
      name: "dashboard-index-ajustes"
    }, {
      path: "chats",
      component: _599226ec,
      name: "dashboard-index-chats"
    }, {
      path: "descuentos",
      component: _6102958a,
      name: "dashboard-index-descuentos"
    }, {
      path: "guardados",
      component: _4a58c986,
      name: "dashboard-index-guardados"
    }, {
      path: "monedas",
      component: _6b1ca1ae,
      name: "dashboard-index-monedas"
    }, {
      path: "premios",
      component: _2fab8e34,
      name: "dashboard-index-premios"
    }]
  }, {
    path: "/eventos",
    component: _00e998ca,
    name: "eventos"
  }, {
    path: "/expirados",
    component: _12adefd4,
    name: "expirados"
  }, {
    path: "/faq",
    component: _7c4fb11f,
    name: "faq"
  }, {
    path: "/foros",
    component: _05217cb9,
    name: "foros"
  }, {
    path: "/gratis",
    component: _25aed2e2,
    name: "gratis"
  }, {
    path: "/inicio-sin-contrasena",
    component: _137a8dac,
    name: "inicio-sin-contrasena"
  }, {
    path: "/login",
    component: _2231dbb2,
    name: "login"
  }, {
    path: "/lp",
    component: _3c6ede61,
    name: "lp"
  }, {
    path: "/marcas",
    component: _233b9350,
    name: "marcas"
  }, {
    path: "/mensajeria",
    component: _0182f756,
    name: "mensajeria"
  }, {
    path: "/notificaciones",
    component: _f3491a5e,
    name: "notificaciones"
  }, {
    path: "/novedades",
    component: _440ecbde,
    name: "novedades"
  }, {
    path: "/nueva-contrasena",
    component: _c67f375e,
    name: "nueva-contrasena"
  }, {
    path: "/nuevos",
    component: _ec68cd2a,
    name: "index-nuevos"
  }, {
    path: "/ofertas",
    component: _2a2e822e,
    name: "ofertas"
  }, {
    path: "/olvide-mi-contrasena",
    component: _59b4b482,
    name: "olvide-mi-contrasena"
  }, {
    path: "/politicas-de-privacidad",
    component: _0b06205b,
    name: "politicas-de-privacidad"
  }, {
    path: "/preferencias",
    component: _d7f8dcea,
    name: "preferences"
  }, {
    path: "/prensa",
    component: _1420a080,
    name: "prensa"
  }, {
    path: "/registro",
    component: _1c63017a,
    name: "registro"
  }, {
    path: "/reglas-de-comunidad",
    component: _40922a10,
    name: "reglas-de-comunidad"
  }, {
    path: "/search",
    component: _647bb42f,
    name: "search"
  }, {
    path: "/sitemap",
    component: _ce3f6a7e,
    children: [{
      path: "",
      component: _194006f8,
      name: "sitemap-index"
    }, {
      path: "promociones",
      component: _83f34c92,
      name: "sitemap-index-promociones"
    }, {
      path: "tiendas",
      component: _098ca3a6,
      name: "sitemap-index-tiendas"
    }, {
      path: "promociones/:slug?",
      component: _87608f22,
      name: "sitemap-index-promociones-slug"
    }]
  }, {
    path: "/sobre-nos",
    component: _c32152f2,
    name: "sobre-nos"
  }, {
    path: "/subscribe",
    component: _78977093,
    name: "subscribe"
  }, {
    path: "/unsubscribe",
    component: _a7460b4c,
    name: "unsubscribe"
  }, {
    path: "/verificar-cuenta",
    component: _c314575e,
    name: "verificar-cuenta"
  }, {
    path: "/compartir/chat",
    component: _17651f91,
    name: "compartir-chat"
  }, {
    path: "/compartir/cupon",
    component: _a62a5a92,
    name: "compartir-cupon"
  }, {
    path: "/compartir/finalizado",
    component: _fd53a9c4,
    name: "compartir-finalizado"
  }, {
    path: "/compartir/oferta",
    component: _18b2a0ad,
    name: "compartir-oferta"
  }, {
    path: "/compartir/cupon/crear",
    component: _009337ec,
    name: "compartir-cupon-crear"
  }, {
    path: "/compartir/oferta/crear",
    component: _6c5b05e2,
    name: "compartir-oferta-crear"
  }, {
    path: "/go/marcas/:id",
    component: _08764e40,
    name: "go-store-slug"
  }, {
    path: "/blog/:category",
    component: _00d3660c,
    name: "blog-category"
  }, {
    path: "/categorias/:slug",
    component: _32baa1c2,
    name: "categorias-slug"
  }, {
    path: "/cupones/:slug",
    component: _4e1f8a54,
    name: "cupones-slug"
  }, {
    path: "/dashboard/:user",
    component: _73334578,
    children: [{
      path: "",
      component: _22b85c0a,
      name: "dashboard-user"
    }, {
      path: "actividad",
      component: _8e220934,
      name: "dashboard-user-actividad"
    }, {
      path: "chats",
      component: _ff3c9678,
      name: "dashboard-user-chats"
    }, {
      path: "descuentos",
      component: _2f79eeb2,
      name: "dashboard-user-descuentos"
    }]
  }, {
    path: "/eventos/:slug",
    component: _019a10fc,
    name: "eventos-slug"
  }, {
    path: "/foros/:slug",
    component: _036adb71,
    name: "foros-slug"
  }, {
    path: "/go/:id",
    component: _1d8faf4a,
    name: "go-id"
  }, {
    path: "/goprize/:hash",
    component: _1d8faf4a,
    name: "goprize-hash"
  }, {
    path: "/lp/:slug",
    component: _3c6ede61,
    name: "lp-slug"
  }, {
    path: "/marcas/:slug",
    component: _233b9350,
    name: "marcas-slug"
  }, {
    path: "/novedades/:slug",
    component: _477c0e6e,
    name: "novedades-slug"
  }, {
    path: "/ofertas/:slug",
    component: _4e1f8a54,
    name: "ofertas-slug"
  }, {
    path: "/open/:slug",
    component: _1d8faf4a,
    name: "open-slug"
  }, {
    path: "/blog/:category/:article",
    component: _22df0382,
    name: "blog-category-article"
  }, {
    path: "/",
    component: _ec68cd2a,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}

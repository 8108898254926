import Vue from 'vue'
import Router from 'vue-router'
import LANG from './lang/pt'

Vue.use(Router)

export function createRouter(ssrContext, createDefaultRouter, config, store) {
    const options = createDefaultRouter(ssrContext, config).options

    return new Router({
        ...options,
        routes: fixRoutes(options.routes, store),
    })
}

function fixRoutes(defaultRoutes) {
    if (process.env.LANG === 'es') return defaultRoutes
    return defaultRoutes.map((route) => {
        switch (true) {
            case route.path === '/acerca-de':
                route.path = `/${LANG.routes.about_us}`
                return route
            case route.path === '/aviso-legal':
                route.path = `/${LANG.routes.legal}`
                return route
            case route.path === '/ayuda':
                route.path = `/${LANG.routes.help}`
                return route
            case route.path === '/blog':
                route.path = `/${LANG.routes.blog}`
                return route
            case route.path === '/cambiar-contrasena':
                route.path = `/${LANG.routes.changePassword}`
                return route
            case route.path === '/categorias':
                route.path = `/${LANG.routes.categories}`
                return route
            case route.path === '/comentados':
                route.path = `/${LANG.routes.commented}`
                return route
            case route.path === '/compartir':
                route.path = `/${LANG.routes.share}`
                return route
            case route.path === '/contacto':
                route.path = `/${LANG.routes.contact}`
                return route
            case route.path === '/contrasena-actualizada-exitosamente':
                route.path = `/${LANG.routes.successChangedPassword}`
                return route
            case route.path === '/cookies':
                route.path = `/${LANG.routes.cookies}`
                return route
            case route.path === '/cupones':
                route.path = `/${LANG.routes.coupons}`
                return route
            case route.path === '/eventos':
                route.path = `/${LANG.routes.events}`
                return route
            case route.path === '/expirados':
                route.path = `/${LANG.routes.expired}`
                return route
            case route.path === '/extension':
                route.path = `/${LANG.routes.extension}`
                return route
            case route.path === '/extension-desinstalada':
                route.path = `/${LANG.routes.extensionUninstalled}`
                return route
            case route.path === '/faq':
                route.path = `/${LANG.routes.faq}`
                return route
            case route.path === '/foros':
                route.path = `/${LANG.routes.forum}`
                return route
            case route.path === '/gratis':
                route.path = `/${LANG.routes.free}`
                return route
            case route.path === '/inicio-sin-contrasena':
                route.path = `/${LANG.routes.loginWithoutPass}`
                return route
            case route.path === '/login':
                route.path = `/${LANG.routes.login}`
                return route
            case route.path === '/marcas':
                route.path = `/${LANG.routes.brands}`
                return route
            case route.path === '/mensajeria':
                route.path = `/${LANG.routes.inbox}`
                return route
            case route.path === '/notificaciones':
                route.path = `/${LANG.routes.notifications}`
                return route
            case route.path === '/novedades':
                route.path = `/${LANG.routes.novelties}`
                return route
            case route.path === '/nueva-contrasena':
                route.path = `/${LANG.routes.newPassword}`
                return route
            case route.path === '/nuevos':
                route.path = `/${LANG.routes.news}`
                return route
            case route.path === '/ofertas':
                route.path = `/${LANG.routes.offers}`
                return route
            case route.path === '/olvide-mi-contrasena':
                route.path = `/${LANG.routes.forgotPassword}`
                return route
            case route.path === '/politicas-de-privacidad':
                route.path = `/${LANG.routes.privacy}`
                return route
            case route.path === '/preferencias':
                route.path = `/${LANG.routes.preferences}`
                return route
            case route.path === '/prensa':
                route.path = `/${LANG.routes.press}`
                return route
            case route.path === '/registro':
                route.path = `/${LANG.routes.register}`
                return route
            case route.path === '/reglas-de-comunidad':
                route.path = `/${LANG.routes.rules}`
                return route
            case route.path === '/search':
                route.path = `/${LANG.routes.search}`
                return route
            case route.path === '/verificar-cuenta':
                route.path = `/${LANG.routes.verifyAccount}`
                return route
            case route.path === '/dashboard':
                route.path = `/${LANG.routes.dashboard}`
                route.children.forEach((elem, index) => {
                    if (elem.path === 'actividad') {
                        route.children[index].path = LANG.routes.activity
                    } else if (elem.path === 'ajustes') {
                        route.children[index].path = LANG.routes.settings
                    } else if (elem.path === 'chats') {
                        route.children[index].path = LANG.routes.chats
                    } else if (elem.path === 'descuentos') {
                        route.children[index].path = LANG.routes.discounts
                    } else if (elem.path === 'guardados') {
                        route.children[index].path = LANG.routes.saved
                    } else if (elem.path === 'monedas') {
                        route.children[index].path = LANG.routes.coins
                    } else if (elem.path === 'premios') {
                        route.children[index].path = LANG.routes.awards
                    }
                })
                return route
            case route.path === '/dashboard/:user':
                route.path = `/${LANG.routes.dashboard}/:user`
                route.children.forEach((elem, index) => {
                    if (elem.path === 'actividad') {
                        route.children[index].path = LANG.routes.activity
                    } else if (elem.path === 'chats') {
                        route.children[index].path = LANG.routes.chats
                    } else if (elem.path === 'descuentos') {
                        route.children[index].path = LANG.routes.discounts
                    }
                })
                return route
            case route.path === '/compartir/chat':
                route.path = `/${LANG.routes.share}/${LANG.routes.chat}`
                return route
            case route.path === '/compartir/cupon':
                route.path = `/${LANG.routes.share}/${LANG.routes.coupon}`
                return route
            case route.path === '/compartir/finalizado':
                route.path = `/${LANG.routes.share}/${LANG.routes.ended}`
                return route
            case route.path === '/compartir/oferta':
                route.path = `/${LANG.routes.share}/${LANG.routes.offer}`
                return route
            case route.path === '/compartir/cupon/crear':
                route.path = `/${LANG.routes.share}/${LANG.routes.coupon}/${LANG.routes.create}`
                route.name = `compartir-${LANG.routes.coupon}-crear`
                return route
            case route.path === '/compartir/oferta/crear':
                route.path = `/${LANG.routes.share}/${LANG.routes.offer}/${LANG.routes.create}`
                route.name = `compartir-${LANG.routes.offer}-crear`
                return route
            case route.path === '/go/marcas/:id':
                route.path = `/go/${LANG.routes.brands}/:id`
                return route
            case route.path === '/blog/:category':
                route.path = `/${LANG.routes.blog}/:category`
                return route
            case route.path === '/categorias/:slug':
                route.path = `/${LANG.routes.categories}/:slug`
                return route
            case route.path === '/cupones/:slug':
                route.path = `/${LANG.routes.coupons}/:slug`
                return route
            case route.path === '/eventos/:slug':
                route.path = `/${LANG.routes.events}/:slug`
                return route
            case route.path === '/foros/:slug':
                route.path = `/${LANG.routes.forum}/:slug`
                return route
            case route.path === '/marcas/:slug':
                route.path = `/${LANG.routes.brands}/:slug`
                return route
            case route.path === '/novedades/:slug':
                route.path = `/${LANG.routes.novelties}/:slug`
                return route
            case route.path === '/ofertas/:slug':
                route.path = `/${LANG.routes.offers}/:slug`
                return route
            case route.path === '/blog/:category/:article':
                route.path = `/${LANG.routes.blog}/:category/:article`
                return route
            case route.path === '/subscribe':
                route.path = `/${LANG.routes.subscribe}`
                return route
            case route.path === '/unsubscribe':
                route.path = `/${LANG.routes.unsubscribe}`
                return route
            default:
                return route
        }
    })
}

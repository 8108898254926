import { Context } from '@nuxt/types'
import LANG from '../lang/pt'

export default async function (ctx: Context) {
    if (
        ctx.route.path &&
        (ctx.route.path.includes('/' + LANG.routes.brands, 0) ||
            ctx.route.path.includes('/' + LANG.routes.offers, 0) ||
            ctx.route.path.includes('/' + LANG.routes.coupons, 0))
    ) {
        try {
            const redirect = await ctx.$api.general.loadRedirects(ctx.route.path)

            if (redirect.status_code && redirect.to && ctx.route.path !== redirect.to) {
                return ctx.redirect(redirect.status_code, redirect.to)
            }
        } catch (e) {
            console.log('Redirect consult failure: ', e)
        }
    }
}

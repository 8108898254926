import { MutationTree } from 'vuex/types'

export const state = () => ({
    home: {
        index: null as null | object,
        commented: null as null | object,
        latest: null as null | object,
    },
    coupons: null as any,
    offers: null as any,
    expired: null as any,
    discount: null as any,
    forums: null as any,
    news: null as any,
    novelty: null as any,
    blog: null as any,
    blogCategory: null as any,
    article: null as any,
    categories: null as any,
    category: null as any,
    free: null as any,
    events: null as any,
    event: null as any,
    stores: null as any,
    store: null as any,
    search: null as any,
    conversations: null as any,
    dashboard: {
        index: null as any,
        tabs: {
            activities: null as any,
            discounts: null as any,
            forums: null as any,
            coins: null as any,
            gifts: null as any,
            ownGifts: null as any,
        },
    },
})

export type RootState = ReturnType<typeof state>

export type ValuesPages = {
    name: keyof RootState
    page: null | object[]
}

export const mutations: MutationTree<RootState> = {
    setValue: (state, { name, page }: ValuesPages) => (state[name] = page),
    setDashboard: (state, profile: object) => {
        state.dashboard.index = profile
    },
    setHome: (state, payload: { key: keyof RootState['home']; value: any }) => {
        state.home[payload.key] = payload.value
    },
    setDashboardTab: (state, tab: { name: keyof RootState['dashboard']['tabs']; data: object }) => {
        state.dashboard.tabs[tab.name] = tab.data
    },
}

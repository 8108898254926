const cookies = {
    access_token_key: 'okd-access-token-key',
    token_expires_at_key: 'okd-token-expiration-key',
    hide_links_warning_key: 'okd-links-warning-key',
    allow_cookies: 'okd-cookies-allowed',
    cookies_bot_config: {
        strict_necessary: 'COOKIE_NECESSARY',
        preferences: 'COOKIE_PREFERENCES',
        statistics: 'COOKIE_STATISTICS',
        marketing: 'COOKIE_MARKETING',
    },
}

const banners = {
    upBanner: 'okd-top-banner-key',
    downBanner: 'okd-bottom-banner-key',
}

export { cookies, banners }

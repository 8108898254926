import { Context } from '@nuxt/types'
import LANG from '../lang/pt'

export default async function (ctx: Context) {
    if (
        ctx.route.path /* ctx.route.path.includes('/' + LANG.routes.events, 0) || */ &&
        (ctx.route.path.includes('/' + LANG.routes.novelties, 0) ||
            ctx.route.path.includes('/' + LANG.routes.forum, 0) ||
            ctx.route.path.includes('/' + LANG.routes.login, 0) ||
            ctx.route.path.includes('/' + LANG.routes.register, 0) ||
            ctx.route.path.includes('/' + LANG.routes.newPassword, 0) ||
            ctx.route.path.includes('/' + LANG.routes.changePassword, 0) ||
            ctx.route.path.includes('/' + LANG.routes.forgotPassword, 0) ||
            ctx.route.path.includes('/' + LANG.routes.loginWithoutPass, 0) ||
            ctx.route.path.includes('/' + LANG.routes.successChangedPassword, 0) ||
            ctx.route.path.includes('/' + LANG.routes.share, 0) ||
            ctx.route.name?.includes(LANG.routes.dashboard + '-index', 0) ||
            ctx.route.name?.includes(LANG.routes.dashboard + '-user', 0))
    ) {
        try {
            return ctx.error({
                message: 'Not found',
                statusCode: 404,
            })
        } catch (e) {
            console.log('Redirect failure: ', e)
        }
    }
}
